import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../ReduxToolkit/App/app";

import { postBasketAddGoodBack } from "../ReduxToolkit/Basket/Basket";

export const addGoodsInBasket = (dispatch, obj, typeGood, goodsVariation) => {
  const token = localStorage.getItem("accessTokenVectortoll");

  if (token) {
    if (typeGood === "isVariations") {
      if (goodsVariation && goodsVariation?.length < 1) {
        dispatch(
          setModalWindowInfo({
            title: "modalWindows.success.notAddGoodInBasket.title",
            label: "modalWindows.success.notAddGoodInBasket.label",
          })
        );
        dispatch(setModalWindowChildrenType("success"));
        dispatch(setModalWindowStatus(true));
      } else {
        dispatch(postBasketAddGoodBack(obj, token));
      }
    } else {
      dispatch(postBasketAddGoodBack(obj, token));
    }
  } else {
    dispatch(setModalWindowStatus(true));
    dispatch(setModalWindowChildrenType("auth"));
  }
};
