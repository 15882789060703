import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../API/api";
import { createLead } from "../../API/createBitrixLead";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

const translationCheckouts = "forms.checkouts.";

const token = localStorage.getItem("accessTokenVectortoll");

export const postOrdersBack = (obj) => (dispatch) => {
  dispatch(setLoadingOrdersBack(true));

  const success = (data) => {
    dispatch(setDataOrdersBack(data?.data));
    const type = "order";
    createLead(obj, type);

    dispatch(setPayment(true));
  };

  const setError = (error) => {
    dispatch(setErrorOrdersBack(error?.response?.data?.message));
    dispatch(backErrorModal(error));
  };

  postRequest({
    url: "/orders",
    obj,
    success,
    setError,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  radioGroupDelivery: [
    {
      id: 1,
      label: `${translationCheckouts}self-delivery`,
      name: "delivery",
      value: "0",
    },
    {
      id: 2,
      label: `${translationCheckouts}new-post`,
      name: "delivery",
      value: "1",
    },
    {
      id: 3,
      label: `${translationCheckouts}adresess-delivery`,
      name: "delivery",
      value: "2",
    },
  ],

  radioGroupPayment: [
    {
      id: 1,
      label: `${translationCheckouts}invoice`,
      name: "payment",
      value: "0",
    },
    // {
    //   id: 2,
    //   label: `${translationCheckouts}cards`,
    //   name: "payment",
    //   value: "1",
    // },
  ],
  newPostDepartments: [{ id: 1, Description: "Виберіть місто" }],
  city: "",
  cities: "",
  deliveryMethod: "1",
  seccess_payment: false,
  payButton: null,

  dataOrdersBack: null,
  loadingOrdersBack: false,
  errorOrdersBack: null,
};

export const order = createSlice({
  name: "good",
  initialState,
  reducers: {
    setPayment(state, action) {
      state.seccess_payment = action.payload;
    },
    setDeliveryMethod(state, action) {
      state.deliveryMethod = action.payload;
    },
    setNewPostCity(state, action) {
      state.city = action.payload;
    },
    successGetNewPostDataCities(state, action) {
      state.cities = action.payload;
    },
    successGetNewPostData(state, action) {
      state.newPostDepartments = action.payload;
    },
    setPayButton(state, action) {
      state.payButton = action.payload;
    },

    setDataOrdersBack(state, action) {
      state.dataOrdersBack = action.payload;
      state.loadingOrdersBack = false;
      state.errorOrdersBack = null;
    },

    setErrorOrdersBack(state, action) {
      state.errorOrdersBack = action.payload;
      state.loadingOrdersBack = false;
    },
    setLoadingOrdersBack(state, action) {
      state.loadingOrdersBack = action.payload;
      state.errorOrdersBack = null;
    },
  },
});

export const {
  setPayment,
  setDeliveryMethod,
  setNewPostCity,
  successGetNewPostDataCities,
  successGetNewPostData,
  setPayButton,
  setLoadingOrdersBack,
  setErrorOrdersBack,
  setDataOrdersBack,
} = order.actions;

export default order.reducer;
