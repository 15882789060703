import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/api";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

export const getBlogArticleBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetBlogArticleBack(true));

  const success = (data) => {
    dispatch(setDataGetBlogArticleBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetBlogArticleBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/posts/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetBlogArticleBack: null,
  loadingGetBlogArticleBack: false,
  errorGetBlogArticleBack: null,
};

export const blogArticle = createSlice({
  name: "blogArticle",
  initialState,
  reducers: {
    setDataGetBlogArticleBack(state, action) {
      state.dataGetBlogArticleBack = action.payload;
      state.loadingGetBlogArticleBack = false;
      state.errorGetBlogArticleBack = null;
    },

    setErrorGetBlogArticleBack(state, action) {
      state.errorGetBlogArticleBack = action.payload;
      state.loadingGetBlogArticleBack = false;
    },
    setLoadingGetBlogArticleBack(state, action) {
      state.loadingGetBlogArticleBack = action.payload;
      state.errorGetBlogArticleBack = null;
    },
  },
});

export const {
  setLoadingGetBlogArticleBack,
  setErrorGetBlogArticleBack,
  setDataGetBlogArticleBack,
} = blogArticle.actions;

export default blogArticle.reducer;
