import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import styles from "./ErrorPage.module.scss";

//commons
import MetaTagsHelmet from "../Commons/MetaTagsHelmet/MetaTagsHelmet";

//app
import { MAIN_PAGE_ROUTE, pathFC } from "../App/Routes/routesConstants";

export const ErrorPage = ({ error = 404 }) => {
  const { t } = useTranslation();

  const message = t("error.title");

  return (
    <>
      <MetaTagsHelmet title={message} />
      <section className={styles.error}>
        <div className="container">
          <div className={styles.number}>{error}</div>
          <div className={styles.message}>{message}</div>
          <NavLink className={styles.link} to={pathFC(MAIN_PAGE_ROUTE)}>
            {t("error.btn")}
          </NavLink>
        </div>
      </section>
    </>
  );
};
