import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getGoodBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetGoodBack(true));

  const success = (data) => {
    dispatch(setDataGetGoodBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetGoodBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/products/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetGoodBack: {},
  loadingGetGoodBack: false,
  errorGetGoodBack: null,
};

export const good = createSlice({
  name: "good",
  initialState,
  reducers: {
    setDataGetGoodBack(state, action) {
      state.dataGetGoodBack = action.payload;
      state.loadingGetGoodBack = false;
      state.errorGetGoodBack = null;
    },

    setErrorGetGoodBack(state, action) {
      state.errorGetGoodBack = action.payload;
      state.loadingGetGoodBack = false;
    },
    setLoadingGetGoodBack(state, action) {
      state.loadingGetGoodBack = action.payload;
      state.errorGetGoodBack = null;
    },
  },
});

export const {
  setLoadingGetGoodBack,
  setErrorGetGoodBack,
  setDataGetGoodBack,
} = good.actions;

export default good.reducer;
