import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/api";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

export const getNewsArticleBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetNewsArticleBack(true));

  const success = (data) => {
    dispatch(setDataGetNewsArticleBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetNewsArticleBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/news/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetNewsArticleBack: null,
  loadingGetNewsArticleBack: false,
  errorGetNewsArticleBack: null,
};

export const newsArticle = createSlice({
  name: "newsArticle",
  initialState,
  reducers: {
    setDataGetNewsArticleBack(state, action) {
      state.dataGetNewsArticleBack = action.payload;
      state.loadingGetNewsArticleBack = false;
      state.errorGetNewsArticleBack = null;
    },

    setErrorGetNewsArticleBack(state, action) {
      state.errorGetNewsArticleBack = action.payload;
      state.loadingGetNewsArticleBack = false;
    },
    setLoadingGetNewsArticleBack(state, action) {
      state.loadingGetNewsArticleBack = action.payload;
      state.errorGetNewsArticleBack = null;
    },
  },
});

export const {
  setLoadingGetNewsArticleBack,
  setErrorGetNewsArticleBack,
  setDataGetNewsArticleBack,
} = newsArticle.actions;

export default newsArticle.reducer;
