import { languageData } from "../../Db/languageData";

export const pathnameWithoutLang = (pathname) => {
  let withoutlang = pathname;
  languageData.map((lang) => {
    if (pathname.includes(lang.key)) {
      withoutlang = pathname.replace(`/${lang.key}`, "");
    }
    return lang;
  });
  return withoutlang;
};
