import { Helmet, HelmetProvider } from "react-helmet-async";
import JsonLd from "./JsonLd";

import { pathnameWithoutLang } from "../../../Utils/language/pathnameWithoutlang";

const MetaTagsHelmet = ({ title, description, keywords, jsonLd }) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title || "Vectortool"}</title>
          {description && (
            <meta name="description" content={description} />
          )}{" "}
          {keywords && <meta name="keywords" content={keywords} />}{" "}
          {description && <meta name="og:description" content={description} />}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title || "Vectortool"} />
          <meta property="og:url" content={window.location.href} />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={
              window.location.origin +
              pathnameWithoutLang(window.location.pathname)
            }
          />
          <link
            rel="alternate"
            hrefLang="uk-ua"
            href={
              window.location.origin +
              pathnameWithoutLang(window.location.pathname)
            }
          />
          <link
            rel="alternate"
            hrefLang="ru-ua"
            href={
              window.location.origin +
              "/ru" +
              pathnameWithoutLang(window.location.pathname)
            }
          />
          <link rel="canonical" href={window.location.href.split('?')[0]}/>
        </Helmet>
      </HelmetProvider>
    </>
  );
};

export default MetaTagsHelmet;
