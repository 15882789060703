import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest } from "../../API/api";

import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../App/app";

//db
import { mainLanguageData } from "../../Db/languageData";

import { user } from "../User/User";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

const token = localStorage.getItem("accessTokenVectortoll");

//delete good
export const deleteGoodFromBasketBack = (id, params) => (dispatch) => {
  const obj = {
    id: id,
  };
  dispatch(setLoadingDeleteBasketGoodBack(true));
  const closeModalWindow = () => {
    dispatch(setModalWindowStatus(false));
    dispatch(setModalWindowChildrenType(""));
  };

  const success = () => {
    dispatch(setLoadingDeleteBasketGoodBack(false));
    closeModalWindow();
    dispatch(getBasketGoodsBack(params));
  };
  const setError = (error) => {
    dispatch(setErrorDeleteBasketGoodBack(error));
    dispatch(backErrorModal(error));
  };

  postRequest({
    url: `/cart/delete`,
    obj,
    success,
    setError,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

export const postBasketAddGoodBack = (obj) => (dispatch) => {
  dispatch(setLoadingPostAddBasketGoodBack(true));

  const success = () => {
    dispatch(setLoadingPostAddBasketGoodBack(false));
    dispatch(
      getBasketGoodsBack({
        lang:
          localStorage.getItem("language_vectortool") || mainLanguageData?.key,
        user_id: localStorage.getItem("userId_vectorTooll"),
      })
    );

    dispatch(
      setModalWindowInfo({
        title: "modalWindows.success.addGoodInBasket.title",
      })
    );
    dispatch(setModalWindowChildrenType("success"));
    dispatch(setModalWindowStatus(true));
  };
  const setError = (error) => {
    dispatch(setErrorPostAddBasketGoodBack(error));
    dispatch(backErrorModal(error));
  };
  postRequest({
    url: `/cart/add`,
    obj,
    success,
    setError,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

export const postBasketAddQuantityGood = (obj) => (dispatch) => {
  dispatch(setLoadingBasketAddQuantityGoodBack(true));
  const success = () => {
    dispatch(setLoadingBasketAddQuantityGoodBack(false));
  };
  const setError = (error) => {
    dispatch(setErrorBasketAddQuantityGoodBack(error));
    dispatch(backErrorModal(error));
  };
  postRequest({
    url: `/cart/add_quantity`,
    obj,
    success,
    setError,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

//get goods
export const getBasketGoodsBack = (params) => (dispatch) => {
  dispatch(setLoadingGetBaskettGoodsBack(true));

  const success = (data) => {
    dispatch(setDataGetBasketGoodsBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetBasketGoodsBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/cart/show`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  dataGetBasketGoodsBack: [],
  loadingGetBasketGoodsBack: false,
  errorGetBasketGoodsBack: null,

  loadingDeleteBasketGoodBack: false,
  errorDeleteBasketGoodBack: null,

  loadingPostAddBasketGoodBack: false,
  errorPostAddBasketGoodBack: null,

  loadingBasketAddQuantityGoodBack: false,
  errorBasketAddQuantityGoodBack: null,
};

export const Basket = createSlice({
  name: "Basket",
  initialState,
  reducers: {
    setDataGetBasketGoodsBack(state, action) {
      state.dataGetBasketGoodsBack = action.payload;
      state.loadingGetBasketGoodsBack = false;
      state.errorGetBasketGoodsBack = null;
    },

    setErrorGetBasketGoodsBack(state, action) {
      state.errorGetBasketGoodsBack = action.payload;
      state.loadingGetBasketGoodsBack = false;
    },
    setLoadingGetBaskettGoodsBack(state, action) {
      state.loadingGetBasketGoodsBack = action.payload;
      state.errorGetBasketGoodsBack = null;
    },

    setErrorDeleteBasketGoodBack(state, action) {
      state.errorDeleteBasketGoodBack = action.payload;
      state.loadingDeleteBasketGoodBack = false;
    },
    setLoadingDeleteBasketGoodBack(state, action) {
      state.loadingDeleteBasketGoodBack = action.payload;
      state.errorDeleteBasketGoodBack = null;
    },

    setErrorPostAddBasketGoodBack(state, action) {
      state.errorPostAddBasketGoodBack = action.payload;
      state.loadingPostAddBasketGoodBack = false;
    },
    setLoadingPostAddBasketGoodBack(state, action) {
      state.loadingPostAddBasketGoodBack = action.payload;
      state.errorPostAddBasketGoodBack = null;
    },

    setErrorBasketAddQuantityGoodBack(state, action) {
      state.errorPostAddBasketGoodBack = action.payload;
      state.loadingBasketAddQuantityGoodBack = false;
    },
    setLoadingBasketAddQuantityGoodBack(state, action) {
      state.loadingBasketAddQuantityGoodBack = action.payload;
      state.errorBasketAddQuantityGoodBack = null;
    },
  },
});

export const {
  setLoadingGetBaskettGoodsBack,
  setErrorGetBasketGoodsBack,
  setDataGetBasketGoodsBack,
  setLoadingDeleteBasketGoodBack,
  setErrorDeleteBasketGoodBack,
  setErrorPostAddBasketGoodBack,
  setLoadingPostAddBasketGoodBack,
  setLoadingBasketAddQuantityGoodBack,
  setErrorBasketAddQuantityGoodBack,
} = Basket.actions;

export default Basket.reducer;
