import React from "react";
import { Route, Switch } from "react-router";
import { ErrorBoundary } from "react-error-boundary";

//routes
import { publicRoutes } from "./Routes/routes";

//components
import ErrorComponent from "../ErrorBoundaryComponent/ErrorBoundaryComponent";

import ErrorPage from "../ErrorPage";

const AppRouter = () => {
  const logError = (error, errorInfo) => {
    console.log({ error, errorInfo });
  };

  return (
    <Switch>
      {publicRoutes.map(({ path, component: Component }) => (
        <Route exact key={path} path={path}>
          <ErrorBoundary FallbackComponent={ErrorComponent} onError={logError}>
            <Component />
          </ErrorBoundary>
        </Route>
      ))}
      <Route>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default AppRouter;
