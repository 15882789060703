import React from "react";
import { useTranslation } from "react-i18next";

//styles
import styles from "./Title.module.scss";

export const Title = ({ label = "", whiteColor = false }) => {
  const { t } = useTranslation();
  return (
    <h1 className={`${styles.title} ${whiteColor && styles.white}`}>
      {t(label)}
    </h1>
  );
};
