import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import styles from "./ConsultModal.module.scss";

//commons
import ModalTitle from "../ModalTitle";
import Input from "../../Inputs/Input/Input";
import { ConsultFormSchema } from "./ConsultFormSchema";

//api
import { createLead } from "../../../../API/createBitrixLead";

const nameFields = {
  name: "name",
  phone: "phone",
};

export const ConsultModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(ConsultFormSchema(t)),
  });

  const onSubmit = (data) => {
    const obj = {
      name: data.name,
      phone: data.phone,
    };
    const type = "consult";
    createLead(obj, type, dispatch);
  };

  return (
    <div className={styles.modal}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle title={t("modalWindows.consult.title")} />
        <div className={styles.label}>{t("modalWindows.consult.label")}</div>
        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.name}
          name={nameFields.name}
          type="text"
          placeholder={t("modalWindows.consult.inputs.name")}
          required
          error={errors?.name}
          register={register}
        />
        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.phone}
          name={nameFields.phone}
          type="text"
          placeholder={t("modalWindows.consult.inputs.phone")}
          required
          error={errors?.phone}
          register={register}
        />
        <button className={styles.btn} type="submit">
          {t("modalWindows.consult.button")}
        </button>
      </form>
    </div>
  );
};
