import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./Counter.module.scss";

//redux
import { postBasketAddQuantityGood } from "../../../ReduxToolkit/Basket/Basket";

export const Counter = ({
  setGoodsVatiation,
  goodsVariation,
  quantity,
  id,
  withBtn = true,
  fromBasket = false,
  price,
  idGood,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.dataGetUserBack);

  const [count, setCount] = useState((fromBasket && quantity) || 0);

  const updateGoodsVariation = (id, count) => {
    let isGoodsInMas = false;
    let newGoodsVariation = goodsVariation?.map((item) => {
      if (item.variant_id === id) {
        isGoodsInMas = true;
        return {
          user_id: user.id,
          product_id: idGood,
          variant_id: id,

          quantity: count,
          price: price,
        };
      }
      return item;
    });
    if (!isGoodsInMas && newGoodsVariation) {
      newGoodsVariation = [
        ...newGoodsVariation,
        {
          user_id: user.id,
          product_id: idGood,
          variant_id: id,
          quantity: count,
          price: price,
        },
      ];
    }
    if (1 > count) {
      for (let i = newGoodsVariation.length; i--; ) {
        if (newGoodsVariation[i].variant_id === id) {
          newGoodsVariation.splice(i, 1);
        }
      }
    }

    newGoodsVariation && setGoodsVatiation(() => newGoodsVariation);
  };

  const decrementFunction = (id, count) => {
    if (count <= 0) return;
    if (fromBasket && count <= 1) return;
    setCount((prev) => prev - 1);
    if (fromBasket) {
      dispatch(
        postBasketAddQuantityGood({
          id: id,
          quantity: `${count - 1}`,
        })
      );
    } else {
      updateGoodsVariation(id, count - 1);
    }
  };

  const incrementFunction = (id, count) => {
    if (count >= 10) return;
    setCount((prev) => prev + 1);
    if (fromBasket) {
      dispatch(
        postBasketAddQuantityGood({
          id: id,
          quantity: `${count + 1}`,
        })
      );
    } else {
      updateGoodsVariation(id, count + 1);
    }
  };
  return (
    <div>
      <div className={styles.count}>
        {withBtn && (
          <button
            className={styles.count__btn}
            onClick={() => decrementFunction(id, count)}
          >
            -
          </button>
        )}
        {count}
        {withBtn && (
          <button
            className={styles.count__btn}
            onClick={() => incrementFunction(id, count)}
          >
            +
          </button>
        )}
      </div>
    </div>
  );
};
