//db
import { mainLanguageData } from "../../../Db/languageData";

//utils
import { findUrlLocalization } from "../../../Utils/language/findUrlLocalization";

export const MAIN_PAGE_ROUTE = "/";
export const BLOG_PAGE_ROUTE = "/blog";
export const NEWS_PAGE_ROUTE = "/news";
export const VIDEO_PAGE_ROUTE = "/video";
export const COMPANY_PAGE_ROUTE = "/company";
export const CONTACTS_PAGE_ROUTE = "/contacts";
export const ERROR_PAGE_ROUTE = "/error";
export const LOGIN_PAGE_ROUTE = "/login";
export const PRODUCTS_PAGE_ROUTE = "/products";
export const CART_PAGE_ROUTE = "/cart";
export const ORDER_PAGE_ROUTE = "/cart/order";
export const THANKS_PAGE_ROUTE = "/cart/order/thanks";
export const DELIVERY_PAGE_ROUTE = "/delivery";
export const PROFILE_PAGE_ROUTE = "/profile";
export const SEARCH_PAGE_ROUTE = "/search";

export const pathFC = (pathName) => {
  const _language = localStorage.getItem("language_vectortool");

  const location = window.location;

  const pathSplit = location.pathname.split("/");

  const isLocalizationUrl = findUrlLocalization(pathSplit);

  const language = _language || isLocalizationUrl || mainLanguageData.key;

  const localizeRoutePath = (path) =>
    language === mainLanguageData.key ? `${path}` : `/${language}${path}`;

  return localizeRoutePath(pathName);
};
